import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
const returnBtnClass = (color, primary, margin) => {
  if (primary && color === "blue") {
    return `btn btn-primary btn-primary__blue ${margin}`
  }
  if (primary && color === "green") {
    return `btn btn-primary btn-primary__green ${margin}`
  }

  if (!primary && color === "blue") {
    return `btn btn-simple btn-simple__primary`
  }
  if (!primary && color === "black") {
    return `btn btn-simple btn-simple__primary`
  }
}

export default function Button({
  text,
  color,
  primary,
  margin,
  gTracking,
  pageName,
}) {
  const btnClass = returnBtnClass(color, primary, margin)

  return gTracking ? (
    <button
      className={btnClass}
      onClick={e => {
        e.preventDefault()
        trackCustomEvent({
          category: "ScheduleService",
          action: "Click",
          label: `${pageName} - Schedule Service`,
        })
      }}
    >
      {text}
    </button>
  ) : (
    <button className={btnClass}>{text}</button>
  )
}
